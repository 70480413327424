import { impersonateSource } from "@/middleware/home";

const HomeView = () => import("./HomeView.vue");

const homeRoutes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
    meta: {
      requiresAuth: true,
      middleware: impersonateSource
    }
  }
];

export default homeRoutes;
