import { useUserStore } from "@/stores/user";
import type { MiddlewareContext } from "@/types";

export const impersonateSource = ({ next, router }: MiddlewareContext) => {
  const store = useUserStore();
  const impersonateSource = localStorage.getItem("IMPERSONATE_SOURCE");

  /* 
  If we get have an IMPERSONATE_SOURCE value and no impersonator_id in the current user, 
  it means we just logged out of impersonation and need to redirect.
  */
  if (!store.currentUser?.impersonator_id && impersonateSource) {
    localStorage.removeItem("IMPERSONATE_SOURCE");
    router.push(impersonateSource);
    return;
  }

  return next();
};
