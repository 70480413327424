import FloatingVue from "floating-vue";
import "floating-vue/dist/style.css";
import type { App } from "vue";
import type { FloatingVueConfig } from "node_modules/floating-vue/dist/config";

export default {
  install: (app: App) => {
    const config: FloatingVueConfig = {
      themes: {
        menu: {
          $extend: "tooltip"
        },
        "truncated-text-tooltip": {
          $extend: "tooltip"
        }
      }
    };

    app.use(FloatingVue, config);
  }
};
